import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, FormSelect } from 'shards-react';
import StepCard from '../components/StepCard';
import { useIdentities } from '../hooks/useGoogleSheetData';
import {
  IdentityType,
  selectIdentityId,
  selectIdentityType,
  setIdentityId,
  setIdentityType
} from '../store/reducers/identity';
import './Identity.scss';
import useBreakpoints from '../hooks/useBreakpoints';
import PrepopulateItem from '../components/PrepopulateItem';

const Source = () => {
  const { smDown } = useBreakpoints();
  const [identities, loading] = useIdentities();
  const dispatch = useDispatch();
  const identityType = useSelector(selectIdentityType);
  const identityId = useSelector(selectIdentityId);

  return (
    <StepCard
      valid={!!identityType && !(identityType === IdentityType.Employee && !identityId)}
      question='Mit welcher Identität teilst du diesen Inhalt?'
    >
      <PrepopulateItem id='identity.identity_type' value={identityType}>
        <ButtonGroup style={{ width: '100%' }} vertical={smDown}>
          <Button
            onClick={() => dispatch(setIdentityType(IdentityType.Company))}
            theme={identityType === IdentityType.Company ? 'dark' : 'light'}
          >
            Als 247GRAD
          </Button>
          <Button
            onClick={() => dispatch(setIdentityType(IdentityType.Employee))}
            theme={identityType === IdentityType.Employee ? 'dark' : 'light'}
          >
            Als individuelle Person
          </Button>
        </ButtonGroup>
      </PrepopulateItem>
      {identityType === IdentityType.Employee && (
        <div className='identity__select'>
          <PrepopulateItem id='identity.identity_id' value={identityId}>
            <FormSelect
              disabled={loading}
              value={identityId}
              onChange={e => dispatch(setIdentityId(e.target.value))}
            >
              <option disabled={!!identityId} value={undefined}>
                – {loading ? 'Personen werden geladen...' : 'Person auswählen'} –
              </option>
              {identities.map(identity => (
                <option key={identity.identityId} value={identity.identityId}>
                  {identity.identityLabel}
                </option>
              ))}
            </FormSelect>
          </PrepopulateItem>
        </div>
      )}
    </StepCard>
  );
};

export default Source;
