import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'shards-react';
import './App.scss';
import PrepopulateAssistant from './components/PrepopulateAssistant';
import Campaign from './steps/Campaign';
import ContentType from './steps/ContentType';
import EmbeddingPost from './steps/EmbeddingPost';
import Identity from './steps/Identity';
import Paid from './steps/Paid';
import Result from './steps/Result';
import Source from './steps/Source';
import Url from './steps/Url';
import {
  selectShowDialogCampaign,
  selectShowDialogContentType,
  selectShowDialogEmbeddingPost,
  selectShowDialogIdentity,
  selectShowDialogPaid,
  selectShowDialogResource,
  selectShowDialogResult
} from './store/rootReducer';
import withStore from './wrappers/withStore';

const App = () => {
  const showDialogSource = useSelector(selectShowDialogResource);
  const showDialogIdentity = useSelector(selectShowDialogIdentity);
  const showDialogPaid = useSelector(selectShowDialogPaid);
  const showDialogContentType = useSelector(selectShowDialogContentType);
  const showDialogEmbeddingPost = useSelector(selectShowDialogEmbeddingPost);
  const showDialogCampaign = useSelector(selectShowDialogCampaign);
  const showResult = useSelector(selectShowDialogResult);
  return (
    <div className='app' id='root'>
      <PrepopulateAssistant />
      <Container className='app__container'>
        <Url />
        {showDialogSource && <Source />}
        {showDialogIdentity && <Identity />}
        {showDialogPaid && <Paid />}
        {showDialogContentType && <ContentType />}
        {showDialogEmbeddingPost && <EmbeddingPost />}
        {showDialogCampaign && <Campaign />}
        {showResult && <Result />}
      </Container>
    </div>
  );
};

export default withStore(App);
