import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormSelect } from 'shards-react';
import StepCard from '../components/StepCard';
import { useSources } from '../hooks/useGoogleSheetData';
import { selectSourceId, setSourceId } from '../store/reducers/source';
import PrepopulateItem from '../components/PrepopulateItem';
const Source = () => {
  const [sources, loading] = useSources();
  const dispatch = useDispatch();
  const sourceId = useSelector(selectSourceId);

  return (
    <StepCard valid={!!sourceId} question='Wo möchtest du diesen Inhalt teilen?'>
      <PrepopulateItem id='source.source_id' value={sourceId}>
        <FormSelect
          disabled={loading}
          value={sourceId}
          onChange={e => dispatch(setSourceId(e.target.value))}
        >
          <option disabled={!!sourceId} value={undefined}>
            – {loading ? 'Netzwerke werden geladen...' : 'Netzwerk auswählen'} –
          </option>
          {[{ sourceId: 'blog', sourceLabel: 'In einem Blogeintrag' }, ...sources].map(source => (
            <option key={source.sourceId} value={source.sourceId}>
              {source.sourceLabel}
            </option>
          ))}
        </FormSelect>
      </PrepopulateItem>
    </StepCard>
  );
};

export default Source;
