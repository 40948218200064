import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActive,
  setActive,
  flushValues,
  selectValues,
  selectPrepopulatedUrl
} from '../store/reducers/prepopulate';
import './PrepopulateAssistant.scss';
import { Button } from 'shards-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const PrepopulateAssistant = () => {
  const dispatch = useDispatch();
  const active = useSelector(selectActive);
  const values = useSelector(selectValues);

  const url = useSelector(selectPrepopulatedUrl);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const to = setTimeout(() => setCopied(false), 1000);
      return () => clearTimeout(to);
    }
  }, [copied, setCopied]);

  const handleClose = () => dispatch(setActive(false));

  useEffect(() => {
    if (active) {
      dispatch(flushValues());
      document.getElementById('root').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [active]);

  return (
    <div className={`prepopulate-assistant ${active ? 'prepopulate-assistant--visible' : ''}`}>
      <div className='prepopulate-assistant__explanation'>
        Wähle die auszufüllenden Felder –&nbsp;
      </div>
      <div className='prepopulate-assistant__count'>{Object.keys(values).length} ausgewählt</div>
      <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
        <Button
          pill
          size='sm'
          theme={copied ? 'success' : 'light'}
          className='prepopulate-assistant__copy'
        >
          URL kopieren
        </Button>
      </CopyToClipboard>
      <Button
        outline
        pill
        size='sm'
        theme='light'
        className='prepopulate-assistant__close'
        onClick={handleClose}
      >
        Beenden
      </Button>
    </div>
  );
};

export default PrepopulateAssistant;
