import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectActive, selectValueSet, toggleValue } from '../store/reducers/prepopulate';
import './PrepopulateItem.scss';

const PrepopulateItem = ({ children, id, value }) => {
  const dispatch = useDispatch();
  const prepopulationActive = useSelector(selectActive);
  const valueSet = useSelector(selectValueSet(id));

  const handleClick = () => (prepopulationActive ? dispatch(toggleValue(id, value)) : undefined);
  const classNames = [
    'prepopulate-item',
    prepopulationActive && !!value && 'prepopulate-item--selectable',
    prepopulationActive && !value && 'prepopulate-item--unselectable',
    valueSet && prepopulationActive && 'prepopulate-item--selected'
  ].filter(Boolean);

  return (
    <div className={classNames.join(' ')} onClick={handleClick}>
      <div className='prepopulate-item__control'>{children}</div>
    </div>
  );
};

PrepopulateItem.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string.isRequired,
  value: PropTypes.any
};

export default PrepopulateItem;
