import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, FormInput } from 'shards-react';
import DropdownApiItems from '../components/DropdownApiItems';
import StepCard from '../components/StepCard';
import ToggleButton from '../components/ToggleButton';
import {
  selectCustomUrl,
  selectCustomUrlValid,
  selectJobOfferUrl,
  selectPostUrl,
  selectProjectUrl,
  selectResourceUrl,
  selectSharingUrl,
  selectSharingUrlValid,
  selectType,
  setCustomUrl,
  setJobOfferUrl,
  setPostUrl,
  setProjectUrl,
  setResourceUrl,
  setType,
  UrlType
} from '../store/reducers/url';
import './Url.scss';
import useBreakpoints from '../hooks/useBreakpoints';
import PrepopulateItem from '../components/PrepopulateItem';

const Url = () => {
  const { smDown } = useBreakpoints();
  const dispatch = useDispatch();
  const type = useSelector(selectType);
  const post_url = useSelector(selectPostUrl);
  const project_url = useSelector(selectProjectUrl);
  const resource_url = useSelector(selectResourceUrl);
  const job_offer_url = useSelector(selectJobOfferUrl);
  const custom_url = useSelector(selectCustomUrl);
  const custom_url_valid = useSelector(selectCustomUrlValid);
  const sharingUrl = useSelector(selectSharingUrl);
  const sharingUrlValid = useSelector(selectSharingUrlValid);
  const handleSelect = value => dispatch(setType(value));

  return (
    <StepCard valid={sharingUrlValid} question='Welchen Inhalt möchtest du teilen?'>
      <PrepopulateItem id='url.type' value={type}>
        <ButtonGroup style={{ width: '100%' }} vertical={!!smDown}>
          <ToggleButton
            label='Blogbeitrag'
            value={UrlType.Post}
            currentValue={type}
            onSelect={handleSelect}
          />
          <ToggleButton
            label='Projekt'
            value={UrlType.Project}
            currentValue={type}
            onSelect={handleSelect}
          />
          <ToggleButton
            label='Ressource'
            value={UrlType.Resource}
            currentValue={type}
            onSelect={handleSelect}
          />
          <ToggleButton
            label='Jobanzeige'
            value={UrlType.JobOffer}
            currentValue={type}
            onSelect={handleSelect}
          />
          <ToggleButton
            label='Andere URL'
            value={UrlType.Custom}
            currentValue={type}
            onSelect={handleSelect}
          />
        </ButtonGroup>
      </PrepopulateItem>
      <div className='url__input'>
        {type === UrlType.Post && (
          <PrepopulateItem id='url.post_url' value={post_url}>
            <DropdownApiItems
              apiQuery='/posts?per_page=30&_fields=id,date,title,link'
              itemLabelResolve={post => post.title.rendered}
              itemValueResolve={post => post.link}
              labelLoading='Beiträge werden geladen...'
              labelSelect='Beitrag auswählen'
              onChangeValue={url => dispatch(setPostUrl(url))}
              value={post_url}
            />
          </PrepopulateItem>
        )}
        {type === UrlType.Project && (
          <PrepopulateItem id='url.project_url' value={project_url}>
            <DropdownApiItems
              apiQuery='/247grad_project?per_page=60&orderby=title&order=asc&_fields=id,date,title,link'
              itemLabelResolve={project => project.title.rendered}
              itemValueResolve={project => project.link}
              labelLoading='Projekte werden geladen...'
              labelSelect='Projekt auswählen'
              onChangeValue={url => dispatch(setProjectUrl(url))}
              value={project_url}
            />
          </PrepopulateItem>
        )}
        {type === UrlType.Resource && (
          <PrepopulateItem id='url.resource_url' value={resource_url}>
            <DropdownApiItems
              apiQuery='/247grad_resource?per_page=60&orderby=title&order=asc&_fields=id,date,title,link'
              itemLabelResolve={resource => resource.title.rendered}
              itemValueResolve={resource => resource.link}
              labelLoading='Ressourcen werden geladen...'
              labelSelect='Ressource auswählen'
              onChangeValue={url => dispatch(setResourceUrl(url))}
              value={resource_url}
            />
          </PrepopulateItem>
        )}
        {type === UrlType.JobOffer && (
          <PrepopulateItem id='url.job_offer_url' value={job_offer_url}>
            <DropdownApiItems
              apiQuery='/247grad_job?per_page=60&orderby=title&order=asc&_fields=id,date,title,acf'
              itemLabelResolve={job => job.title.rendered}
              itemValueResolve={job => job.acf.link}
              labelLoading='Jobs werden geladen...'
              labelSelect='Job auswählen'
              onChangeValue={url => dispatch(setJobOfferUrl(url))}
              value={job_offer_url}
            />
          </PrepopulateItem>
        )}
        {type === UrlType.Custom && (
          <PrepopulateItem id='url.custom_url' value={custom_url}>
            <FormInput
              placeholder='https://...'
              value={custom_url}
              onChange={e => dispatch(setCustomUrl(e.target.value))}
              type='url'
              valid={!!custom_url && custom_url_valid}
              invalid={!!custom_url && !custom_url_valid}
            />
          </PrepopulateItem>
        )}
      </div>
      {sharingUrl && (
        <div className='url__test'>
          <Button onClick={() => window.open(sharingUrl)} title='URL überprüfen' theme='light'>
            URL überprüfen
          </Button>
        </div>
      )}
    </StepCard>
  );
};

export default Url;
