import initialize from '../initialize';

const initialState = {
  campaign_id: initialize('campaign.campaign_id', '')
};

/* SELECTORS */
export const selectCampaignId = state => state.campaign.campaign_id;

/* MUTATORS */

const CAMPAIGN_ID_SET = 'campaign/campaign_id/SET';
export const setCampaignId = campaign_id => ({
  type: CAMPAIGN_ID_SET,
  payload: {
    campaign_id
  }
});

/* REDUCER */

export default function campaign(state = initialState, action) {
  switch (action.type) {
    case CAMPAIGN_ID_SET: {
      const { campaign_id } = action.payload;
      return {
        ...state,
        campaign_id
      };
    }
    default:
      return state;
  }
}
