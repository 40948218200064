import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup } from 'shards-react';
import StepCard from '../components/StepCard';
import ToggleButton from '../components/ToggleButton';
import { PaidType, selectPaidType, setPaidType } from '../store/reducers/paid';
import './Identity.scss';
import useBreakpoints from '../hooks/useBreakpoints';
import PrepopulateItem from '../components/PrepopulateItem';

const Source = () => {
  const { smDown } = useBreakpoints();
  const dispatch = useDispatch();
  const paidType = useSelector(selectPaidType);
  const handleSelect = type => dispatch(setPaidType(type));
  return (
    <StepCard valid={!!paidType} question='Auf welche Weise teilst du diesen Inhalt?'>
      <PrepopulateItem id='paid.paid_type' value={paidType}>
        <ButtonGroup style={{ width: '100%' }} vertical={smDown}>
          <ToggleButton
            label='Als organischen Beitrag'
            value={PaidType.Organic}
            currentValue={paidType}
            onSelect={handleSelect}
          />
          <ToggleButton
            label='Als bezahlte Anzeige'
            value={PaidType.Paid}
            currentValue={paidType}
            onSelect={handleSelect}
          />
        </ButtonGroup>
      </PrepopulateItem>
    </StepCard>
  );
};

export default Source;
