import { useEffect, useState } from 'react';

const Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500
};

const useBreakpoints = () => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const handleResize = () => setViewportWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [window, handleResize]);

  // Up
  const upMatches = {
    smUp: viewportWidth >= Breakpoints.sm,
    mdUp: viewportWidth >= Breakpoints.md,
    lgUp: viewportWidth >= Breakpoints.lg,
    xlUp: viewportWidth >= Breakpoints.xl
  };

  // Down
  const downMatches = {
    smDown: viewportWidth < Breakpoints.md,
    mdDown: viewportWidth < Breakpoints.lg,
    lgDown: viewportWidth < Breakpoints.xl,
    xlDown: viewportWidth < Breakpoints.xxl
  };

  // Exact
  const exactMatches = {
    xs: !upMatches.smUp,
    sm: upMatches.smUp && downMatches.smDown,
    md: upMatches.mdUp && downMatches.mdDown,
    lg: upMatches.lgUp && downMatches.lgDown,
    xl: upMatches.xlUp && downMatches.xlDown,
    xxl: !downMatches.xlDown
  };

  return { ...upMatches, ...downMatches, ...exactMatches };
};

export default useBreakpoints;
