import validUrl from 'valid-url';
import initialize from '../initialize';

export const UrlType = {
  Post: 1,
  Project: 2,
  Resource: 3,
  JobOffer: 4,
  Custom: 5
};

const initialState = {
  type: initialize('url.type', undefined),
  post_url: initialize('url.post_url', undefined),
  project_url: initialize('url.project_url', undefined),
  resource_url: initialize('url.resource_url', undefined),
  job_offer_url: initialize('url.job_offer_url', undefined),
  custom_url: initialize('url.custom_url', '')
};

/* SELECTORS */
export const selectType = state => state.url.type;
export const selectPostUrl = state => state.url.post_url;
export const selectProjectUrl = state => state.url.project_url;
export const selectResourceUrl = state => state.url.resource_url;
export const selectJobOfferUrl = state => state.url.job_offer_url;
export const selectCustomUrl = state => state.url.custom_url;
export const selectCustomUrlValid = state => !!validUrl.isHttpsUri(selectCustomUrl(state));

export const selectSharingUrl = state => {
  switch (state.url.type) {
    case UrlType.Post:
      return state.url.post_url;
    case UrlType.Project:
      return state.url.project_url;
    case UrlType.Resource:
      return state.url.resource_url;
    case UrlType.JobOffer:
      return state.url.job_offer_url;
    default:
      return state.url.custom_url;
  }
};

export const selectSharingUrlValid = state => !!validUrl.isHttpsUri(selectSharingUrl(state));

/* MUTATORS */

const TYPE_SET = 'url/type/SET';
export const setType = type => ({
  type: TYPE_SET,
  payload: {
    type
  }
});

const POST_URL_SET = 'url/post_url/SET';
export const setPostUrl = url => ({
  type: POST_URL_SET,
  payload: {
    url
  }
});

const PROJECT_URL_SET = 'url/project_url/SET';
export const setProjectUrl = url => ({
  type: PROJECT_URL_SET,
  payload: {
    url
  }
});

const RESOURCE_URL_SET = 'url/resource_url/SET';
export const setResourceUrl = url => ({
  type: RESOURCE_URL_SET,
  payload: {
    url
  }
});

const JOB_OFFER_URL_SET = 'url/job_offer_url/SET';
export const setJobOfferUrl = url => ({
  type: JOB_OFFER_URL_SET,
  payload: {
    url
  }
});

const CUSTOM_URL_SET = 'url/custom_url/SET';
export const setCustomUrl = url => ({
  type: CUSTOM_URL_SET,
  payload: {
    url
  }
});

/* REDUCER */

export default function url(state = initialState, action) {
  switch (action.type) {
    case TYPE_SET: {
      const { type } = action.payload;
      return {
        ...state,
        type
      };
    }
    case POST_URL_SET: {
      const { url } = action.payload;
      return {
        ...state,
        post_url: url
      };
    }
    case PROJECT_URL_SET: {
      const { url } = action.payload;
      return {
        ...state,
        project_url: url
      };
    }
    case RESOURCE_URL_SET: {
      const { url } = action.payload;
      return {
        ...state,
        resource_url: url
      };
    }
    case JOB_OFFER_URL_SET: {
      const { url } = action.payload;
      return {
        ...state,
        job_offer_url: url
      };
    }
    case CUSTOM_URL_SET: {
      const { url } = action.payload;
      return {
        ...state,
        custom_url: url
      };
    }
    default:
      return state;
  }
}
