import PropTypes from 'prop-types';
import React from 'react';
import { FormSelect } from 'shards-react';
import useApiData from '../hooks/useApiData';

const DropdownApiItems = ({
  apiQuery,
  itemLabelResolve,
  itemValueResolve,
  labelLoading,
  labelSelect,
  onChangeValue,
  value
}) => {
  const [items, loading] = useApiData(apiQuery);

  return (
    <FormSelect disabled={loading} value={value} onChange={e => onChangeValue(e.target.value)}>
      <option disabled={!!value} value=''>
        – {loading ? labelLoading : labelSelect} –
      </option>
      {items.map(item => (
        <option
          key={item.id}
          value={itemValueResolve(item)}
          dangerouslySetInnerHTML={{ __html: itemLabelResolve(item) }}
        />
      ))}
    </FormSelect>
  );
};

DropdownApiItems.propTypes = {
  apiQuery: PropTypes.string.isRequired,
  itemLabelResolve: PropTypes.func,
  itemValueResolve: PropTypes.func,
  labelLoading: PropTypes.string,
  labelSelect: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  value: PropTypes.any
};

DropdownApiItems.defaultProps = {
  itemLabelResolve: item => item.ID,
  itemValueResolve: item => item.ID,
  labelLoading: 'Wird geladen...',
  labelSelect: 'Auswählen'
};

export default DropdownApiItems;
