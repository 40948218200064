import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormInput } from 'shards-react';
import StepCard from '../components/StepCard';
import {
  selectEmbeddingPostUrl,
  selectEmbeddingPostUrlValid,
  setEmbeddingPostUrl
} from '../store/reducers/embedding_post';
import './EmbeddingPost.scss';
import PrepopulateItem from '../components/PrepopulateItem';

const EmbeddingPost = () => {
  const dispatch = useDispatch();
  const embedding_post_url = useSelector(selectEmbeddingPostUrl);
  const embedding_post_url_valid = useSelector(selectEmbeddingPostUrlValid);

  return (
    <StepCard
      valid={embedding_post_url_valid}
      question='Wie lautet die URL des Beitrags, in dem du den Inhalt verlinken wirst?'
    >
      <PrepopulateItem id='embedding_post.embedding_post_url' value={embedding_post_url}>
        <FormInput
          placeholder='https://...'
          value={embedding_post_url}
          onChange={e => dispatch(setEmbeddingPostUrl(e.target.value))}
          type='url'
          valid={!!embedding_post_url && embedding_post_url_valid}
          invalid={!!embedding_post_url && !embedding_post_url_valid}
        />
      </PrepopulateItem>
      {embedding_post_url_valid && (
        <div className='embedding-post__test'>
          <Button
            onClick={() => window.open(embedding_post_url)}
            title='URL überprüfen'
            theme='light'
          >
            URL überprüfen
          </Button>
        </div>
      )}
    </StepCard>
  );
};

export default EmbeddingPost;
