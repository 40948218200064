import initialize from '../initialize';

export const ParameterType = {
  Utm: 1,
  Itm: 2
};

const initialState = {
  source_id: initialize('source.source_id', undefined)
};

/* SELECTORS */
export const selectSourceId = state => state.source.source_id;
export const selectParameterType = state =>
  state.source.source_id === 'blog' ? ParameterType.Itm : ParameterType.Utm;

/* MUTATORS */
const SOURCE_ID_SET = 'source/source_id/SET';
export const setSourceId = source_id => ({
  type: SOURCE_ID_SET,
  payload: {
    source_id
  }
});

/* REDUCER */

export default function source(state = initialState, action) {
  switch (action.type) {
    case SOURCE_ID_SET: {
      const { source_id } = action.payload;
      return {
        ...state,
        source_id
      };
    }
    default:
      return state;
  }
}
