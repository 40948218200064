import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import rootReducer from './rootReducer';

const middleware = [];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(
    createLogger({
      collapsed: true,
      diff: true
    })
  );
}

export default createStore(rootReducer, applyMiddleware(...middleware));
