import React from 'react';
import { Button } from 'shards-react';
import PropTypes from 'prop-types';

const ToggleButton = ({ currentValue, label, value, onSelect }) => (
  <Button onClick={() => onSelect(value)} theme={currentValue === value ? 'dark' : 'light'}>
    {label}
  </Button>
);

ToggleButton.propTypes = {
  currentValue: PropTypes.any,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default ToggleButton;
