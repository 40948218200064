import React from 'react';
import { Provider } from 'react-redux';
import store from '../store/store';

const withStore = BaseComponent => {
  const WithStoreComponent = props => {
    return (
      <Provider store={store}>
        <BaseComponent {...props} />
      </Provider>
    );
  };
  WithStoreComponent.displayName = 'withStore';
  return WithStoreComponent;
};

export default withStore;
