import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, CardTitle } from 'shards-react';
import './StepCard.scss';

const StepCard = ({ question, valid, children, forceScroll }) => {
  const cardRef = useRef(null);
  useEffect(() => {
    if ((!valid || forceScroll) && cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [forceScroll, valid, cardRef]);

  return (
    <div ref={cardRef} className={`step-card ${valid ? 'step-card--valid' : ''}`}>
      <svg
        className={`step-card__icon ${valid ? 'step-card__icon--visible' : ''}`}
        viewBox='0 0 512 512'
      >
        <path
          fill='#17c671'
          d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'
        ></path>
      </svg>
      <svg
        className={`step-card__icon ${!valid ? 'step-card__icon--visible' : ''}`}
        viewBox='0 0 512 512'
      >
        <path
          fill='#ffb400'
          d='M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z'
        ></path>
      </svg>
      <Card className={`step-card__card ${valid ? 'step-card__card--valid' : ''}`}>
        <CardBody>
          <Row>
            <Col lg='4'>
              <CardTitle>{question}</CardTitle>
            </Col>
            <Col lg='8'>{children}</Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

StepCard.propTypes = {
  question: PropTypes.string.isRequired,
  valid: PropTypes.bool,
  children: PropTypes.any,
  forceScroll: PropTypes.bool
};

export default StepCard;
