import { useEffect, useState } from 'react';
import csvToArray from '../helpers/csvToArray';

const GOOGLE_SHEET_ID =
  '2PACX-1vTpFt6sJsDU1XW2AFYZwcfqh_6tIYsXnL1GLtB1_Knue0SrtwiKOf3YhPYFXYFYIjc84vZw6xpp_5vd';

const useGoogleSheetData = (gid, orderByKey = undefined) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      `https://docs.google.com/spreadsheets/d/e/${GOOGLE_SHEET_ID}/pub?gid=${gid}&single=true&output=csv`,
      { cache: 'no-cache' }
    )
      .then(res => {
        if (res.status !== 200) throw new Error('Request failed');
        return res.text();
      })
      .then(res => {
        const temp = csvToArray(res);
        if (orderByKey) {
          temp.sort((a, b) => {
            if (a[orderByKey] < b[orderByKey]) return -1;
            if (a[orderByKey] > b[orderByKey]) return 1;
            return 0;
          });
        }
        setData(temp);
        setLoading(false);
      });
  }, []);
  return [data, loading];
};

/* EXPORTED HOOKS TO BE USED */
export const useSources = () => useGoogleSheetData('0', 'sourceLabel');
export const useIdentities = () => useGoogleSheetData('855399392', 'identityLabel');
export const useContentTypes = () => useGoogleSheetData('1767323756');
export const useCampaigns = () => useGoogleSheetData('879387871');
