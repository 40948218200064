import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormSelect } from 'shards-react';
import StepCard from '../components/StepCard';
import { useCampaigns } from '../hooks/useGoogleSheetData';
import { selectCampaignId, setCampaignId } from '../store/reducers/campaign';
import PrepopulateItem from '../components/PrepopulateItem';

const Campaign = () => {
  const [campaigns, loading] = useCampaigns();
  const dispatch = useDispatch();
  const campaignId = useSelector(selectCampaignId);

  return (
    <StepCard valid={!!campaignId} question='Ist der Inhalt Teil einer Kampagne?'>
      <PrepopulateItem id='campaign.campaign_id' value={campaignId}>
        <FormSelect
          disabled={loading}
          value={campaignId}
          onChange={e => dispatch(setCampaignId(e.target.value))}
        >
          <option disabled={!!campaignId} value={undefined}>
            – {loading ? 'Kampagnen werden geladen...' : 'Bitte auswählen'} –
          </option>
          <option value={'none'}>– Keine Kampagne –</option>
          {campaigns.map(campaign => (
            <option key={campaign.campaignId} value={campaign.campaignId}>
              {campaign.campaignLabel}
            </option>
          ))}
        </FormSelect>
      </PrepopulateItem>
    </StepCard>
  );
};

export default Campaign;
