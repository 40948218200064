const initialState = {
  active: false,
  values: {}
};

/* SELECTORS */
export const selectActive = state => state.prepopulate.active;
export const selectValues = state => state.prepopulate.values;
export const selectValueSet = id => state => !!state.prepopulate.values[id];

// Build URL
export const selectPrepopulatedUrl = state => {
  const urlFixed = `${window.location.protocol}//${window.location.host}`;
  const params = new URLSearchParams();

  for (const id in state.prepopulate.values) {
    if (Object.prototype.hasOwnProperty.call(state.prepopulate.values, id)) {
      params.append(id, JSON.stringify(state.prepopulate.values[id]));
    }
  }

  return `${urlFixed}?${params.toString()}`;
};

/* MUTATORS */

const ACTIVE_SET = 'prepopulate/active/SET';
export const setActive = active => ({
  type: ACTIVE_SET,
  payload: {
    active
  }
});

const VALUES_FLUSH = 'prepopulate/values/FLUSH';
export const flushValues = () => ({
  type: VALUES_FLUSH
});

const VALUE_SET = 'prepopulate/values/SET';
export const setValue = (id, value) => ({
  type: VALUE_SET,
  payload: {
    id,
    value
  }
});

const VALUE_UNSET = 'prepopulate/values/UNSET';
export const unsetValue = id => ({
  type: VALUE_UNSET,
  payload: {
    id
  }
});

const VALUE_TOGGLE = 'prepopulate/values/TOGGLE';
export const toggleValue = (id, value) => ({
  type: VALUE_TOGGLE,
  payload: {
    id,
    value
  }
});

/* REDUCER */

export default function url(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_SET: {
      const { active } = action.payload;
      return {
        ...state,
        active
      };
    }
    case VALUES_FLUSH: {
      return {
        ...state,
        values: {}
      };
    }
    case VALUE_SET: {
      const { id, value } = action.payload;
      const newValues = { ...state.values };
      newValues[id] = value;
      return {
        ...state,
        values: newValues
      };
    }
    case VALUE_UNSET: {
      const { id } = action.payload;
      const newValues = { ...state.values };
      if (typeof newValues[id] !== 'undefined') {
        delete newValues[id];
      }
      return {
        ...state,
        values: newValues
      };
    }
    case VALUE_TOGGLE: {
      const { id, value } = action.payload;
      const newValues = { ...state.values };
      if (typeof newValues[id] !== 'undefined') {
        delete newValues[id];
      } else {
        newValues[id] = value;
      }
      return {
        ...state,
        values: newValues
      };
    }

    default:
      return state;
  }
}
