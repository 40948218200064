import { combineReducers } from 'redux';
import content_type, { selectContentTypeId } from './reducers/content_type';
import embedding_post, {
  selectEmbeddingPostUrl,
  selectEmbeddingPostUrlValid
} from './reducers/embedding_post';
import identity, { IdentityType, selectIdentityId, selectIdentityType } from './reducers/identity';
import paid, { PaidType, selectPaidType } from './reducers/paid';
import source, { ParameterType, selectParameterType, selectSourceId } from './reducers/source';
import url, { selectSharingUrl, selectSharingUrlValid } from './reducers/url';
import campaign, { selectCampaignId } from './reducers/campaign';
import prepopulate from './reducers/prepopulate';

export default combineReducers({
  campaign,
  content_type,
  embedding_post,
  identity,
  paid,
  prepopulate,
  source,
  url
});

// Visibility determinators
export const selectShowDialogResource = state => selectSharingUrlValid(state);
export const selectShowDialogIdentity = state =>
  selectShowDialogResource(state) && !!selectSourceId(state) && selectSourceId(state) !== 'blog';
export const selectShowDialogPaid = state =>
  selectShowDialogIdentity(state) && selectIdentityType(state) === IdentityType.Company;
export const selectShowDialogContentType = state =>
  (selectShowDialogIdentity(state) &&
    selectIdentityType(state) === IdentityType.Employee &&
    selectIdentityId(state)) ||
  (selectShowDialogPaid(state) && selectPaidType(state) === PaidType.Organic);
export const selectShowDialogEmbeddingPost = state => selectSourceId(state) === 'blog';
export const selectShowDialogCampaign = state =>
  (selectShowDialogContentType(state) && !!selectContentTypeId(state)) ||
  (selectShowDialogPaid(state) && selectPaidType(state) === PaidType.Paid) ||
  (selectShowDialogEmbeddingPost(state) && selectEmbeddingPostUrlValid(state));
export const selectShowDialogResult = state =>
  selectShowDialogCampaign(state) && !!selectCampaignId(state);

/* Build single parameters */
const selectParamSource = state => {
  if (selectParameterType(state) === ParameterType.Itm) {
    return 'internal';
  }
  return selectSourceId(state);
};
const selectParamMedium = state => {
  if (selectParameterType(state) === ParameterType.Utm) {
    if (selectIdentityType(state) === IdentityType.Employee) {
      return 'employee';
    } else if (selectIdentityType(state) === IdentityType.Company) {
      if (selectPaidType(state) === PaidType.Paid) {
        return 'paid';
      } else {
        return 'organic';
      }
    }
  }
  return undefined;
};
const selectParamCampaign = state => {
  const campaign = selectCampaignId(state);
  if (campaign && campaign !== 'none') {
    return campaign;
  }
  return undefined;
};
const selectParamContent = state => {
  if (selectParameterType(state) === ParameterType.Itm) {
    return 'post';
  } else if (
    selectIdentityType(state) === IdentityType.Employee ||
    (selectIdentityType(state) === IdentityType.Company &&
      selectPaidType(state) === PaidType.Organic)
  ) {
    return selectContentTypeId(state);
  }
  return undefined;
};
const selectParamTerm = state => {
  if (selectParameterType(state) === ParameterType.Itm) {
    return selectEmbeddingPostUrl(state).replace('https://247grad.de/blog', '');
  } else if (selectIdentityType(state) === IdentityType.Employee) {
    return selectIdentityId(state);
  }
  return undefined;
};

/* Build parametrized url */
export const selectParametrizedUrl = state => {
  let urlBase = selectSharingUrl(state);
  const prefix = selectParameterType(state) === ParameterType.Itm ? 'itm' : 'utm';

  const source = selectParamSource(state);
  const medium = selectParamMedium(state);
  const content = selectParamContent(state);
  const term = selectParamTerm(state);
  const campaign = selectParamCampaign(state);

  let params = new URLSearchParams();

  const origURL = new URL(urlBase);
  params = origURL.searchParams;
  urlBase = origURL.origin + origURL.pathname;

  if (source) params.append(`${prefix}_source`, source);
  if (medium) params.append(`${prefix}_medium`, medium);
  if (content) params.append(`${prefix}_content`, content);
  if (term) params.append(`${prefix}_term`, term);
  if (campaign) params.append(`${prefix}_campaign`, campaign);

  return `${urlBase}?${params.toString()}`;
};
