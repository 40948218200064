/* Taken from https://sebhastian.com/javascript-csv-to-array/ */
export default function csvToArray(str, delimiter = ',') {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf('\r\n')).split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf('\r\n') + 2).split('\r\n');

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows
    .map(row => {
      const values = row.split(delimiter);
      // If any of the values is not set, leave out the entire row
      if (values.includes(undefined) || values.includes('')) return false;
      const el = headers.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    })
    .filter(Boolean);

  // return the array
  return arr;
}
