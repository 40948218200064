import { useEffect, useState } from 'react';

const useApiData = apiQuery => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://247grad.de/wp-json/wp/v2${apiQuery}`)
      .then(res => {
        if (res.status !== 200) throw new Error('Request failed');
        return res.json();
      })
      .then(res => {
        setData(res);
        setLoading(false);
      });
  }, []);
  return [data, loading];
};

export default useApiData;
