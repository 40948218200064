import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css';
import './index.scss';

import { polyfill } from 'seamless-scroll-polyfill';
polyfill();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
