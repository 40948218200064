import validUrl from 'valid-url';
import initialize from '../initialize';

const initialState = {
  embedding_post_url: initialize('embedding_post.embedding_post_url', '')
};

/* SELECTORS */
export const selectEmbeddingPostUrl = state => state.embedding_post.embedding_post_url;
export const selectEmbeddingPostUrlValid = state =>
  !!validUrl.isHttpsUri(selectEmbeddingPostUrl(state));

/* MUTATORS */

const EMBEDDING_POST_URL_SET = 'embedding_post/embedding_post_url/SET';
export const setEmbeddingPostUrl = embedding_post_url => ({
  type: EMBEDDING_POST_URL_SET,
  payload: {
    embedding_post_url
  }
});

/* REDUCER */

export default function url(state = initialState, action) {
  switch (action.type) {
    case EMBEDDING_POST_URL_SET: {
      const { embedding_post_url } = action.payload;
      return {
        ...state,
        embedding_post_url
      };
    }
    default:
      return state;
  }
}
