import initialize from '../initialize';

export const IdentityType = {
  Company: 1,
  Employee: 2
};

const initialState = {
  identity_type: initialize('identity.identity_type', undefined),
  identity_id: initialize('identity.identity_id', undefined)
};

/* SELECTORS */
export const selectIdentityType = state => state.identity.identity_type;
export const selectIdentityId = state => state.identity.identity_id;

/* MUTATORS */
const IDENTITY_TYPE_SET = 'identity/identity_type/SET';
export const setIdentityType = identity_type => ({
  type: IDENTITY_TYPE_SET,
  payload: {
    identity_type
  }
});

const IDENTITY_ID_SET = 'identity/identity_id/SET';
export const setIdentityId = identity_id => ({
  type: IDENTITY_ID_SET,
  payload: {
    identity_id
  }
});

/* REDUCER */

export default function identity(state = initialState, action) {
  switch (action.type) {
    case IDENTITY_TYPE_SET: {
      const { identity_type } = action.payload;
      return {
        ...state,
        identity_type
      };
    }
    case IDENTITY_ID_SET: {
      const { identity_id } = action.payload;
      return {
        ...state,
        identity_id
      };
    }
    default:
      return state;
  }
}
