const queryString = require('query-string');

export default function initialize(id, defaultValue = undefined) {
  const search = window.location.search;
  const parsed = queryString.parse(search);

  if (typeof parsed[id] !== 'undefined') {
    try {
      return JSON.parse(parsed[id]);
    } catch (e) {
      return defaultValue;
    }
  }
  return defaultValue;
}
