import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from 'shards-react';
import StepCard from '../components/StepCard';
import ToggleButton from '../components/ToggleButton';
import { useContentTypes } from '../hooks/useGoogleSheetData';
import { selectContentTypeId, setContentTypeId } from '../store/reducers/content_type';
import { selectSourceId } from '../store/reducers/source';
import useBreakpoints from '../hooks/useBreakpoints';
import PrepopulateItem from '../components/PrepopulateItem';

const ContentType = () => {
  const { smDown } = useBreakpoints();
  const [contentTypes, loading] = useContentTypes();
  const dispatch = useDispatch();
  const contentTypeId = useSelector(selectContentTypeId);
  const sourceId = useSelector(selectSourceId);

  const filteredContentTypes = contentTypes.filter(
    contentType => contentType.sourceId === sourceId
  );

  // Make sure to unselect invalid content types afterwards
  useEffect(() => {
    if (
      !loading &&
      !filteredContentTypes.find(contentType => contentType.contentTypeId === contentTypeId)
    ) {
      dispatch(setContentTypeId(undefined));
    }
  }, [filteredContentTypes, sourceId]);

  const handleSelect = contentTypeId => dispatch(setContentTypeId(contentTypeId));

  return (
    <StepCard valid={!!contentTypeId} question='Wie erscheint der Inhalt?'>
      <PrepopulateItem id='content_type.content_type_id' value={contentTypeId}>
        <ButtonGroup style={{ width: '100%' }} vertical={smDown}>
          {loading && (
            <Button theme='light' disabled>
              Informationen werden geladen...
            </Button>
          )}
          {filteredContentTypes.map(contentType => (
            <ToggleButton
              key={contentType.contentTypeId}
              value={contentType.contentTypeId}
              currentValue={contentTypeId}
              label={contentType.contentTypeLabel}
              onSelect={handleSelect}
            />
          ))}
        </ButtonGroup>
      </PrepopulateItem>
    </StepCard>
  );
};

export default ContentType;
