import initialize from '../initialize';

const initialState = {
  content_type_id: initialize('content_type.content_type_id', undefined)
};

/* SELECTORS */
export const selectContentTypeId = state => state.content_type.content_type_id;

/* MUTATORS */
const CONTENT_TYPE_ID_SET = 'content_type/content_type_id/SET';
export const setContentTypeId = content_type_id => ({
  type: CONTENT_TYPE_ID_SET,
  payload: {
    content_type_id
  }
});

/* REDUCER */
export default function content_type(state = initialState, action) {
  switch (action.type) {
    case CONTENT_TYPE_ID_SET: {
      const { content_type_id } = action.payload;
      return {
        ...state,
        content_type_id
      };
    }
    default:
      return state;
  }
}
