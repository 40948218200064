import initialize from '../initialize';

export const PaidType = {
  Organic: 1,
  Paid: 2
};

const initialState = {
  paid_type: initialize('paid.paid_type', undefined)
};

/* SELECTORS */
export const selectPaidType = state => state.paid.paid_type;

/* MUTATORS */
const PAID_TYPE_SET = 'paid/paid_type/SET';
export const setPaidType = paid_type => ({
  type: PAID_TYPE_SET,
  payload: {
    paid_type
  }
});

/* REDUCER */
export default function paid(state = initialState, action) {
  switch (action.type) {
    case PAID_TYPE_SET: {
      const { paid_type } = action.payload;
      return {
        ...state,
        paid_type
      };
    }
    default:
      return state;
  }
}
