import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector, useDispatch } from 'react-redux';
import { Button, FormInput, InputGroup, InputGroupAddon } from 'shards-react';
import StepCard from '../components/StepCard';
import './Result.scss';
import { selectParametrizedUrl } from '../store/rootReducer';
import useBreakpoints from '../hooks/useBreakpoints';
import { setActive } from '../store/reducers/prepopulate';

const Result = () => {
  const dispatch = useDispatch();
  const { smDown } = useBreakpoints();
  const [copied, setCopied] = useState(false);
  const sharingUrl = useSelector(selectParametrizedUrl);

  useEffect(() => {
    if (copied) {
      const to = setTimeout(() => setCopied(false), 1000);
      return () => clearTimeout(to);
    }
  }, [copied, setCopied]);

  return (
    <StepCard valid forceScroll question='🎉 &nbsp;&nbsp;Bereit zum Teilen!'>
      <InputGroup vertical={smDown} className='result__url'>
        <FormInput value={sharingUrl} onChange={undefined} readOnly />
        <InputGroupAddon type='append'>
          <CopyToClipboard text={sharingUrl} onCopy={() => setCopied(true)}>
            <Button theme={copied ? 'success' : 'dark'} title='Kopieren'>
              Kopieren
            </Button>
          </CopyToClipboard>
        </InputGroupAddon>
      </InputGroup>
      <button className='result__prepopulate' onClick={() => dispatch(setActive(true))}>
        Möchtest du diese UTM-Konfiguration für andere vorbereiten?
      </button>
    </StepCard>
  );
};

export default Result;
